import React from 'react';
import { withProviders } from '../../../contexts/main/withProviders';
import { withChallengeData } from '../../../contexts/ChallengeDataProvider/withChallengeData';
import { withLocation } from '../../../contexts/Location/withLocation';
import { IThankYouPageProps } from './interfaces';
import { serverTimelineTypeToClientTimelineString } from '@wix/challenges-web-library';
import { Pages } from '../../../contexts/Location/LocationContext';
import get from 'lodash/get';
import { useSettings } from '@wix/tpa-settings/react';

import { Button, ButtonPriority } from 'wix-ui-tpa';

import { classes, st } from './Widget.st.css';
import { withInviteLink } from '../../../contexts/InviteLink/InviteLinkContext';
import { ButtonNames } from '../../../contexts/main/biInterfaces';
import { applyProviders } from '../../../services/applyProviders';
import { withUser } from '../../../contexts/User/withUser';
import {
  AccessType,
  Challenge,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { State as ParticipantState } from '@wix/ambassador-challenges-v1-participant/types';
import { FedopsInteraction } from '../../ChallengesPage/Widget/constants';
import settingsParams from '../../ChallengesPage/settingsParams';
import { setElementToViewport } from '../../../services/scrollHelpers';
import {
  useEnvironment,
  useFedopsLogger,
  useTranslation,
  useBi,
} from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';

const ThankYouPage: React.FC<IThankYouPageProps> = (props) => {
  const pageName = 'thank-you-page';
  const settings = useSettings();
  const { interactionEnded } = useFedopsLogger();
  const { t } = useTranslation();
  const bi = useBi();

  const [isGoToChallengeClicked, updateIsGoToChallengeClicked] =
    React.useState(false);
  const { isMobile, language } = useEnvironment();

  const wrapperRef = React.useRef();
  React.useEffect(() => {
    const wrapperDOM = wrapperRef?.current;

    setElementToViewport(wrapperDOM);
  }, []);

  React.useEffect(() => {
    const participantState = props?.participant?.transitions?.[0].state;
    if (
      isGoToChallengeClicked &&
      participantState !== ParticipantState.PAYMENT_STARTED
    ) {
      goToChallengePage(challenge);
    } else {
      setTimeout(() => {
        props.updateParticipant();
      }, 200);
    }
  }, [isGoToChallengeClicked, props.participant]);

  React.useEffect(() => {
    interactionEnded(FedopsInteraction.JoinUserToChallenge);
  }, []);

  const goToChallengePage = async (_challenge: Challenge) => {
    await bi.report(
      memberWebAppButtonClickV2({
        buttonName: ButtonNames.BackToChallenge,
      }),
    );

    props.goToPage({
      pageId: Pages.Details,
      challengeId: _challenge?.settings?.seo?.slug,
    });
  };

  const {
    challengeData: { challenge },
  } = props;
  const isJoined = props.userType !== ParticipantState.JOIN_REQUESTED;

  const durationString = serverTimelineTypeToClientTimelineString(
    challenge?.settings?.timelineType as any,
    language,
    t,
    'challenge-card.duration-string.ongoing',
    `challenge-card.duration-string.flexible.days_icu`,
    `challenge-card.duration-string.flexible.weeks_icu`,
    'challenge-card.duration-string.no-limit',
  );

  return (
    <main
      data-hook={pageName}
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      <div className={classes.wrapper} ref={wrapperRef}>
        <h1 className={classes.title} data-hook={`${pageName}-title`}>
          {isJoined
            ? t(`thank-you.page.title`)
            : t(`thank-you.page.secret.title`)}
        </h1>
        {challenge.settings?.accessRestrictions?.accessType ===
          AccessType.PRIVATE && (
          <p
            className={classes.description}
            data-hook={`${pageName}-description`}
          >
            {isJoined
              ? t(`thank-you.page.description`)
              : t(`thank-you.page.secret.description`)}
          </p>
        )}

        <div className={classes.contentBox}>
          <h2
            className={classes.contentTitle}
            data-hook={`${pageName}-content-title`}
          >
            {challenge.settings?.description.title}
          </h2>

          <p className={classes.text} data-hook={`${pageName}-text-summary`}>
            {durationString} ·{' '}
            {t(`thank-you.page.info.steps_icu`, {
              count: get(challenge, 'stepsSummary.stepsNumber', 0),
            })}
          </p>

          {isJoined && settings.get(settingsParams.displayOneApp) ? (
            <p
              className={classes.text}
              data-hook={`${pageName}-text-details-link`}
            >
              <button
                onClick={() => props.goToInviteLink()}
                className={classes.link}
              >
                {t('thank-you.page.view-in-app')}
              </button>
            </p>
          ) : (
            ''
          )}

          <Button
            priority={ButtonPriority.primary}
            disabled={isGoToChallengeClicked}
            className={classes.ctaButton}
            data-hook={`${pageName}-cta`}
            fullWidth={false}
            onClick={async (e) => {
              updateIsGoToChallengeClicked(true);
              e && e.preventDefault();
            }}
          >
            {t(isJoined ? 'thank-you.page.back' : 'thank-you.page.return.cta')}
          </Button>
        </div>
      </div>
    </main>
  );
};

ThankYouPage.displayName = 'thank-you-page';

export const ThankYouPageWithProviders: React.FC<any> = applyProviders(
  ThankYouPage,
  [withInviteLink, withLocation, withChallengeData, withUser],
);

export default applyProviders(ThankYouPageWithProviders, [
  withProviders,
]) as React.FC;
